// External Libraries
import axios from "axios";

/*********************
Base API URL
**********************/
// let BASE_URL = process.env.REACT_APP_API_URI;
let BASE_URL = "dev";

if (!BASE_URL) {
  throw new Error("API URI not set");
}

/** **************************
Create custom instance of Axios
**************************** */
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export default api;
