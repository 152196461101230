import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

// Receipts
import TJReceiptApproved from "./TJReceiptApproved";
import TJReceiptDeclined from "./TJReceiptDeclined";
import TJReceiptApprovedExample from "./TJReceiptApprovedExample";
import TJReceiptDeclinedExample from "./TJReceiptDeclinedExample";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<i class="fas fa-arrow-circle-down"></i>}
    {...props}
  />
))(({ theme }) => ({
  marginLeft: 0,
  justifyContent: "flex-start",
  border: "none",
  padding: 0,
  alignItems: "center",
  fontFamily: "Montserrat-Regular",
  textTransform: "uppercase",
  color: "#00C1FF",

  "&.alt-colour": {
    color: "#EE236B",

    "& .MuiAccordionSummary-expandIconWrapper": {
      color: "#EE236B",
      fontSize: 20,
      marginTop: -3,
    },
  },

  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00C1FF",
    fontSize: 20,
    marginTop: -3,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    justifyContent: "space-between",
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
    padding: 0,
    flex: "unset",

    marginRight: 10,
    fontFamily: "Montserrat-Regular",
    justifyContent: "space-between",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
}));

export default function TJReceiptAccordion() {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panelad-content" id="panel1d-header">
          <h4 style={{ display: "inline-block", marginRight: 235 }}>
            APPROVED RECEIPT TEMPLATE
          </h4>
          <h4 style={{ display: "inline-block" }}>APPROVED RECEIPT EXAMPLE</h4>
        </AccordionSummary>
        <AccordionDetails>
          <TJReceiptApproved />
          <TJReceiptApprovedExample />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          className="alt-colour"
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <h4 style={{ display: "inline-block", marginRight: 205 }}>
            DECLINE TRANSACTION TEMPLATE
          </h4>
          <h4 style={{ display: "inline-block" }}>
            DECLINE TRANSACTION EXAMPLE
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <TJReceiptDeclined />
          <TJReceiptDeclinedExample />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
