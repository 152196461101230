import React from 'react';

// React Router
import { useParams } from 'react-router';

// Base Layout Components
import ApiNavigation from '../components/ApiNavigation';
import { TJPageHeader } from 'tj-components';
import { Grid } from '@material-ui/core';

// Helper Methods
import apis from '../helpers/apis';
import TJSwagger from '../components/TJSwagger';

// APIs
import jposYAML from '../yaml/jpos.yaml';
import junctionPOSData from '../content/Junction@POS/content';

// Layout helpers
import MaterialTable from 'material-table';
import { tableIcons } from '../helpers/tableHelpers';
import styled, { keyframes } from 'styled-components';
import { fadeInUp, fadeInLeft, fadeInRight } from 'react-animations';
import jPosimg from '../static/images/jpospmain.png';
import jPosLogo from '../static/images/jposlogo.png';
import AdditionalResourceImg from '../static/images/additional-resources-bg.png';
import TJTabs from '../components/TJTabs';
import TJTextAccordion from '../components/TJTextAccordion';
import TJReceiptAccordion from '../components/TJReceiptAccordion';
import TJReceiptTextAccordion from '../components/TJRecieptTextAccordion';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;
const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const AnimationContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const AnimationContainerMobile = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const FadeInUp = styled.div`
  top: 35px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: -40px;
  text-align: center;
  z-index: 1;
  position: absolute;
  animation: 1s ${fadeInUpAnimation};
  width: 345px;

  @media screen and (min-width: 1025px) {
    width: 400px;
  }
  img {
    width: 300px;
    @media screen and (min-width: 600px) {
      width: 345px;
    }
    @media screen and (min-width: 1025px) {
      width: 400px;
    }
  }
`;

const FadeInRight = styled.div`
  bottom: 10%;
  right: 10%;
  z-index: 2;
  position: absolute;
  animation: 1s ${fadeInRightAnimation};

  img {
    width: 120px;
    @media screen and (min-width: 600px) {
      width: 150px;
    }
  }
`;

const FadeInLeft = styled.div`
  animation: 0.5s ${fadeInLeftAnimation};
  margin-top: 15px;
  width: 300px;
  height: 300px;
  border-radius: 15px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 130, 217, 1) 58%,
    rgba(0, 193, 255, 1) 93%
  );
  @media screen and (min-width: 600px) {
    width: 345px;
    height: 345px;
  }
  @media screen and (min-width: 1025px) {
    width: 400px;
    height: 400px;
  }
`;

const APiPage = () => {
  const { id } = useParams();
  const currentAPI = apis.find((api) => id === api.slug);

  return (
    <>
      <ApiNavigation pageTitle={currentAPI.name} />
      <TJPageHeader title={currentAPI.name} />
      <Grid container spacing={0}>
        <Grid container className="tj-grid-container" id="overview">
          <Grid xs={12} md={6} item>
            <AnimationContainerMobile>
              <FadeInUp>
                <img src={jPosimg} alt="Junction@POS" />
                <FadeInRight>
                  <img
                    style={{ width: 120 }}
                    src={jPosLogo}
                    alt="Junction@POS Logo"
                  />
                </FadeInRight>
              </FadeInUp>
              <FadeInLeft />
            </AnimationContainerMobile>
            <h2>Overview</h2>
            <p>
              <strong>
                Transaction Junction offers a complete integrated payments and
                electronics product procurement application for deployment in
                retail environments.
              </strong>
            </p>
            <p>
              Junction@POS is a java application responsible for managing
              communication between the POS, supported PIN Entry Devices (PED’s)
              and the payment switch which manages communications with the
              various banks. Junction@POS is supported on Windows with Microsoft
              SQL Express or SQL Server. Ubuntu LTS with SQL Express for Linux
              will be supported in future.
            </p>
            <div className="hr-container">
              <hr className="small-hr" />
            </div>
          </Grid>
          <Grid
            xs={6}
            md={6}
            item
            style={{
              position: 'relative',
            }}
          >
            <AnimationContainer>
              <FadeInUp>
                <img src={jPosimg} alt="Junction@POS" />
                <FadeInRight>
                  <img
                    style={{ width: 120 }}
                    src={jPosLogo}
                    alt="Junction@POS Logo"
                  />
                </FadeInRight>
              </FadeInUp>
              <FadeInLeft />
            </AnimationContainer>
          </Grid>
          <Grid xs={12} item>
            <h4 className="standalone-h4">PCI Compliance</h4>
            <p>
              Junction@POS is P2PE 2.0 compliant. Enter “Transaction Junction”
              in the search box at the following link for details: <br />
              <a
                className="body-link"
                href="https://www.pcisecuritystandards.org/assessors_and_solutions/point_to_point_encryption_solutions?agree=true"
                target="_blank"
                rel="noreferrer"
              >
                Official PCI Security Standards Council Site - Verify PCI
                Compliance, Download Data Security and Credit Card Security
                Standards
              </a>
            </p>
            <h4 className="standalone-h4">Supported Deployment Models</h4>
            <p>
              If there is any uncertainty on which model to use, please discuss
              with your Transaction Junction representative.
            </p>
            <TJTextAccordion />
          </Grid>
        </Grid>

        <Grid
          item
          style={{
            background:
              'transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box',

            color: '#fff',
            margin: '0 auto',
          }}
          className="tj-grid-container"
          id="implementation-guide"
        >
          <h2>Implementation Guide</h2>
          <p>
            Once a project has been lodged with Transaction Junction,
            development against the API can be performed by the integrator. The
            API is published as a Swagger 2.0 document, implying that much of
            the client-side code can be generated using the Swagger toolset
            encompassing a wide variety of programming languages and application
            frameworks. The Swagger 2.0 YAML document is provided to the
            integrator by Transaction Junction. This should reduce the amount of
            effort required to complete the integration significantly, and is
            the recommended approach, as it will largely eliminate any syntactic
            issues in the exchange of messages between the two parties.
            Transaction Junction will provide contact details of our integration
            specialist for assistance during this process, if required.
          </p>
          <p>
            When development has been completed, the first phase of integration
            testing will commence. Transaction Junction provides a simulator
            accessible via the internet. Details will be provided by the
            Transaction Junction integration specialist allocated to the
            project. The integrator will be expected to complete all tests
            documented in a test guide, provided by Transaction Junction. The
            test results will be evaluated by the Transaction Junction
            integrator, and any issues highlighted. When all tests are
            successfully implemented, the project will move into a user
            acceptance testing process. This is largely performed by the
            integrator, using the relevant Junction@POS deployment and a test
            PIN entry device (PED), as provided (typically) by the acquiring
            bank.
          </p>
          <p>
            Once a mutually agreed pilot date has been determined, the solution
            will be piloted in the production environment, typically in a small
            number of stores. Thereafter, rollout of the solution to all stores
            can be planned and executed.
          </p>
          <div className="hr-container">
            <hr className="small-hr light" />
          </div>
          <h3 className="alt-h3">Simulator Scope</h3>
          <p>
            The simulator provides an environment against which the POS can test
            the message integration without needing a PIN Entry Device (PED),
            test cards or Junction@POS install. It is not intended as
            replacement for end to end system testing in any but the simplest
            integrations.
          </p>
          <p>
            Responses are returned to the POS based on amount and test the
            structure and sequences of the messages.
          </p>
          <p>Test cases exist for the following transaction types:</p>
          <ul>
            <li>Purchase</li>
            <li>Purchase with cashback</li>
            <li>Cash Withdrawal</li>
            <li>Token</li>
            <li>Payment</li>
            <li>Payment Refund</li>
            <li>Push Funds</li>
            <li>e-Fuel</li>
            <li>Voucher</li>
          </ul>
          <p>
            Functionality involving PED input cannot be tested using the
            simulator and will require a physical device and UAT connectivity.
            Scenarios involving custom routing and specific providers are also
            not catered for by the simulator and should be discussed with the
            Transaction Junction project team.
          </p>
          {/* <div className="hr-container">
            <hr className="small-hr light" />
          </div>
          <h3 className="alt-h3">UAT</h3> */}
        </Grid>
        <Grid
          item
          xs={12}
          id="operations"
          className="tj-grid-container"
          style={{
            background: '#00C1FF1A',
            color: '#00C1FF',
          }}
        >
          <h2 style={{ color: '#000' }}>Available Operations</h2>
          <h2 className="alt-h2">Transactions</h2>
          <TJTabs data={junctionPOSData} />
          {/* The section below is still in refinement, thus inline css was used */}
          <div className="hr-container">
            <hr className="small-hr" />
          </div>
          <h2 className="alt-h2">Administration</h2>
          <h3 style={{ marginBottom: 0, color: '#000', fontSize: 16 }}>
            AVAILABLE PEDS
          </h3>
          <p style={{ color: '#000' }}>
            Stores with wireless PED’s can request a list of PED’s that are not
            busy with a transaction. Because the PED’s aren’t physically
            connected to a POS it’s possible that another cashier has a
            transaction in progress. The cashier can physically select a device
            listed by the above request to continue with a transaction.
          </p>
        </Grid>
        <Grid item className="tj-grid-container" id="page-api">
          <TJSwagger url={jposYAML} />
        </Grid>
        <Grid
          id="receipt-formatting"
          style={{
            background: '#C8C8D21A',
          }}
          item
          className="tj-grid-container"
        >
          <h2>Receipt Formatting</h2>
          <p>
            The example receipts provided below cover the minimum requirements
            for receipt printing. The receipt template indicates the required
            headings with the associated fields, while the receipt on the right
            is provided as an example of a populated receipt.
          </p>
          <p>
            Fields shown in blue are conditional and should only be printed if
            present in the response.
          </p>
          <p>
            <strong>
              Most fields can be printed directly from the response however the
              following need to be evaluated:{' '}
            </strong>
          </p>
          <TJReceiptTextAccordion />
          <div className="hr-container">
            <hr className="small-hr" />
          </div>
          <TJReceiptAccordion />
        </Grid>
        <Grid
          id="response-codes"
          item
          xs={12}
          style={{
            background:
              'transparent linear-gradient(270deg, #0082D9 0%, #00C1FF 100%) 0% 0% no-repeat padding-box',
            color: '#fff',
            margin: '0 auto',
          }}
          className="tj-grid-container"
        >
          <h2>Response Codes</h2>
          <MaterialTable
            style={{ background: 'transparent', boxShadow: 'none' }}
            icons={tableIcons}
            columns={[
              {
                title: 'Response Code',
                field: 'code',
                type: 'numeric',
                align: 'left',
                headerStyle: {
                  width: '20%',
                },
                cellStyle: {
                  color: '#fff',
                  width: '20%',
                },
              },
              {
                title: 'Status',
                field: 'status',
                headerStyle: {
                  width: '40%',
                },
                cellStyle: {
                  color: '#fff',
                  width: '40%',
                },
              },
              {
                title: 'Description',
                field: 'message',
                cellStyle: {
                  width: '60%',
                  color: '#fff',
                },
                headerStyle: {
                  width: '60%',
                },
              },
            ]}
            options={{
              pageSize: 10,
              toolbar: false,
              paginationType: 'stepped',
              thirdSortClick: false,
              draggable: false,
              headerStyle: {
                background: 'transparent',
                border: 'none',
                color: '#fff',
                fontFamily: 'montserrat-bold',
              },
            }}
            data={junctionPOSData.responseCodes}
            title=""
          />
        </Grid>
        <Grid
          item
          id="additional-resources"
          style={{
            background: '#FFFFFFD9',
            backgroundImage: `url(${AdditionalResourceImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
          className="tj-grid-container"
        >
          <h2>Additional Resources</h2>
          <h3 className="light-h3">INTEGRATION ASSISTANCE</h3>
          <p>
            <strong>SWAGGER FILE</strong>
          </p>
          <p>
            Swagger is a tool for documenting REST APIs. It allows for defining
            the APIs in a way that is both human and machine-readable. The
            human-readable aspects allow for creation of documentation of the
            API. The API documentation in this document was derived from the
            Swagger file. The machine-readable aspect of Swagger allows for code
            generation to be performed to create code, in a variety of
            programming languages and for several frameworks. The resultant code
            base abstracts away much of the mundane work required in
            implementing the API. This technique has been used by Transaction
            Junction in the creation of the REST API interface. The Swagger
            file, card.yaml, represents the API in Swagger 2.0 format. This
            version was specifically selected as it supports considerably more
            code generation targets than OpenAPI 3.0, the newer format. The
            Swagger file is available from Transaction Junction.
          </p>
          <p>
            <strong>GENERATION OF REFERENCE</strong>
          </p>
          <p>
            The reference property in the transaction bodies is a unique value
            that identifies the transaction over the full length of the
            authorisation and settlement chains. It is a UUID and follows RFC
            4122 (https://tools.ietf.org/html/rfc4122).
          </p>
          <p>
            <strong>JAVA GENERATION</strong>
          </p>
          <p>
            The UUID class in the Java SDK makes it easy to generate a UUID
            value for the reference property on all API calls to Junction@POS.
            <br />
            <strong>An example follows:</strong>
          </p>
          <div className="code-box-container">
            <div className="body-container">
              <div className="code-container">
                <code>
                  import java.util.UUID;
                  <br />
                  public class GenerateReference {`{`}
                  <br />
                  &nbsp;&nbsp;public static final void main(String[] args) {`{`}
                  <br />
                  &nbsp;&nbsp;UUID ref = UUID.randomUUID(); {`}`}
                  <br />
                  {`}`}
                </code>
              </div>
            </div>
          </div>

          <p>
            <strong>C# GENERATION</strong>
          </p>
          <p>
            An example program generating a GUID follows. Essentially, a GUID
            and UUID are the same concept.
          </p>
          <div className="code-box-container">
            <div className="body-container">
              <div className="code-container">
                <code>
                  using System;
                  <br />
                  namespace uuid
                  <br />
                  &nbsp;&nbsp;{`{`} class Program
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`{`}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;static
                  void Main(string[] args)
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {`{`}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Guid
                  g;
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g
                  = Guid.NewGuid();
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console.WriteLine(g);
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {`}`}
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {`}`}
                  <br />
                  &nbsp;&nbsp;&nbsp;{`}`}
                </code>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default APiPage;
