// External Libraries
import { combineReducers } from "redux";

// Reducers
import userReducer from "./userReducer";

/*****************************************
Combine all reducers into one state object
******************************************/
const rootReducer = combineReducers({
  userInfo: userReducer,
});

export default rootReducer;
