const apis = [
  {
    name: "Junction@POS REST API",
    excerpt:
      "This API describes the interface between the POS and Junction@POS and will be used by POS integrators wishing to integrate their POS systems for the purpose of online transaction processing via Transaction Junction.",
    slug: "junction@POS-rest-api",
  },
];

export default apis;
