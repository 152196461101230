import React from "react";
import styled from "styled-components";

const ReceiptContainer = styled.div`
  width: 350px;
  height: 100%;
  background: #fff;
  margin-right: 125px;
  border: 1px solid #000;
  padding: 10px;
  color: #000;
  display: inline-block;
  font-family: "VT323", monospace;
`;

const TransactionDetails = styled.div`
  display: flex;
  margin: 0 10px 10px;
  font-size: 10px;
  justify-content: space-between;
`;

const CenterHeading = styled.div`
  text-align: center;
  padding: 1.6rem;
`;

const Detail = styled.div`
  width: 50%;
  font-size: 10px;
`;

const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
`;

const Amount = styled.div`
  width: 50%;
  font-size: 10px;
  text-align: right;
`;

const TJReceiptDeclinedExample = () => {
  return (
    <ReceiptContainer>
      <CenterHeading>
        <h4>Transaction Declined (91)</h4>
        <h4>Customer Copy</h4>
      </CenterHeading>
      <TransactionDetails>
        <Detail>&lt;&lt;POSDate&gt;&gt;</Detail>
        <Detail>&lt;&lt;POSTime&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;store&gt;&gt;</Detail>
        <Detail>&lt;&lt;pos&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;card&gt;&gt;</Detail>
        <Detail>&lt;&lt;seq&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;CryptogramType&gt;&gt;</Detail>
        <Detail>&lt;&lt;cryptogram&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>&lt;&lt;apl&gt;&gt;</Detail>
        <Detail>&lt;&lt;tvr&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>AID:</Detail>
        <Detail>&lt;&lt;aid&gt;&gt;</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>CVM Results:</Detail>
        <Detail>cvm_results</Detail>
      </TransactionDetails>
      <TransactionDetails>
        <Detail>UTI:</Detail>
        <Detail>&lt;&lt;reference&gt;&gt;</Detail>
      </TransactionDetails>

      <PaymentDetails>
        <Detail>Declined Amount:</Detail>
        <Amount>R &lt;&lt;amount&gt;&gt;</Amount>
      </PaymentDetails>
    </ReceiptContainer>
  );
};

export default TJReceiptDeclinedExample;
