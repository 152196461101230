import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

// Assets
import JunctionPOSImg from "../static/images/Junction@POS.png";

const TJTab = withStyles((theme) => ({
  root: {
    textTransform: "uppercase",
    minWidth: 130,
    width: 130,
    height: 60,
    fontWeight: 800,
    marginRight: theme.spacing(4),
    fontFamily: "Montserrat-Bold",
    background: "#00C1FF33",
    wordBreak: "keep-all",
    marginBottom: 20,
    borderRadius: 10,
    color: "#000000",
    "&:hover": {
      color: "#00C1FF",
      background: "#034EA2",
    },
    "&$selected": {
      color: "#00C1FF",
      background: "#034EA2",
    },
    "&:focus": {
      color: "#00C1FF",
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography style={{ color: "#000" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {},
}));

export default function TJTabs({ data }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        textColor="primary"
        variant="fullWidth"
        aria-label="transaction-types"
      >
        {data.transactionTypes.map((tranType, index) => {
          return (
            <TJTab label={tranType.type} key={index} {...a11yProps(index)} />
          );
        })}
      </Tabs>

      {data.transactionTypes.map((tranType, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {tranType.description}

            {tranType.type === "PURCHASE" && (
              <div style={{ marginTop: 20 }}>
                <img src={JunctionPOSImg} alt="jpos example" />
                <div style={{ textAlign: "left", marginBottom: 20 }}>
                  <h3
                    style={{
                      marginBottom: 0,
                      color: "#000",
                      fontSize: 16,
                    }}
                  >
                    EXAMPLE
                  </h3>
                  <a className="body-link" href="javascript:void(0)">
                    https://url:port/tjpos/v1/card
                  </a>
                </div>
                <div className="code-box-container">
                  <div className="header-container">
                    <div>Request</div>
                    <div>Response</div>
                  </div>
                  <div className="body-container">
                    <div className="code-container">
                      <code>
                        &#123;
                        <br />
                        &nbsp;&nbsp;"reference":
                        "aa901a39-5c3c-4a5e-816c-b9acd9f66622",
                        <br />
                        &nbsp;&nbsp;"chain": "MyBrand",
                        <br />
                        &nbsp;&nbsp;"amount": 500,
                        <br />
                        &nbsp;&nbsp;"store": "MyStore",
                        <br />
                        &nbsp;&nbsp;"pos": "57290093",
                        <br />
                        &nbsp;&nbsp;"tran": "PURCHASE"
                        <br />
                        &#125;
                      </code>
                    </div>
                    <div>
                      <code>
                        &#123;
                        <br />
                        &nbsp;&nbsp;"response_code": "00",
                        <br />
                        &nbsp;&nbsp;"response_text":
                        <br />
                        &nbsp;&nbsp;"Approved",
                        <br />
                        &nbsp;&nbsp;"amount": 500,
                        <br />
                        &nbsp;&nbsp;"reference":
                        "aa901a39-5c3c-4a5e-816c-b9acd9f66622",
                        <br />
                        &nbsp;&nbsp;"pos": "57290093",
                        <br />
                        &nbsp;&nbsp;"store": "RESTSIM00000001",
                        <br />
                        &nbsp;&nbsp;"tran": "PURCHASE",
                        <br />
                        &nbsp;&nbsp;"data": [],
                        <br />
                        &nbsp;&nbsp;"tip": 100,
                        <br />
                        &nbsp;&nbsp;"seq": "000012",
                        <br />
                        &nbsp;&nbsp;"acc_type": "Credit",
                        <br />
                        &nbsp;&nbsp;"receipt": []
                        <br />
                        &#125;
                      </code>
                    </div>
                  </div>
                </div>
                <p>
                  *Example shows basic use case with sample values. Contact your
                  Transaction Junction representative for Chain, Store and POS
                  values.
                </p>
              </div>
            )}
          </TabPanel>
        );
      })}
    </div>
  );
}
