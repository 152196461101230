import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Redux Setup
import { thunk_fetch_user_info } from './actions/userActions';

// Material UI Setup
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// Helpers
import _ from 'lodash';
import { styles } from './appStyles';

// Router Setup
import { Switch, Route } from 'react-router-dom';

// Base Layout Components
import { TJHeader, TJLoader } from 'tj-components';
import Logo from './static/images/Logo.svg';
import TJSymbol from './static/images/TJSymbol.svg';
import LoaderSpinner from './static/images/loader.svg';
import TJFooter from './components/TJFooter';
// import NetworkError from './components/NetworkError';

// Pages
import Home from './pages/Home';
import ApiPage from './pages/ApiPage.js';

// Setup base colors for Meterial UI
const theme = createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#0098d8',
    },
    secondary: {
      main: '#01c1fe',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Montserrat-Regular', '"Helvetica Neue"', 'Arial'].join(','),
  },
  props: {
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },
  overrides: {
    MuiTableCell: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': ['Montserrat-Regular'],
        },
      },
    },
  },
});

const useStyles = makeStyles(styles);

const App = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <div
        style={{
          width: '50%',
          height: 500,
          display: 'flex',
          margin: '0 auto',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TJLoader image={Logo} spinner={LoaderSpinner} />
      </div>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="overlay">
        <div className="iconContainer">
          <div className="phone">
            {/* <i className="fa fa-repeat"></i> */}
            <img src={TJSymbol} alt="tj symbol" />
          </div>
          <p>Rotate your device</p>
        </div>
      </div>
      <div className={(classes.root, 'hide-mobile')}>
        <div className={classes.container}>
          <Switch>
            <Route exact path="/">
              <TJHeader
                logo={Logo}
                mobileMenu
                onLogoClick={() => console.log('')}
              />
              <Home />
            </Route>
            <Route path="/:id" children={<ApiPage />} />
          </Switch>
        </div>
        <TJFooter logo={TJSymbol} />
      </div>
    </ThemeProvider>
  );
};

export default App;
