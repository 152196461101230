import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#034EA2",

    fontFamily: "Montserrat-Regular",
    padding: "5rem 10%",
    [theme.breakpoints.up("xl")]: {
      padding: "5rem 20%",
    },
  },
  footerSections: {
    display: "flex",
    paddingBottom: "2rem",
    borderBottom: "1px solid #C8C8D2",
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  brandSection: {
    marginTop: 20,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  contactSection: {
    marginTop: 20,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  usefulLinsSection: {
    marginTop: 20,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  footerSocket: {
    padding: "2rem 0 0",
    color: "#C8C8D2",
    textAlign: "right",
  },
  brandImage: {
    width: 50,
  },
  brandTitle: {
    fontSize: 16,
    color: "#fff",
    textTransform: "uppercase",
    marginBottom: 0,
  },
  brandSubTitle: {
    fontSize: 16,
    color: "#C8C8D2",
    marginTop: 0,
  },
  contactTitle: {
    color: "#C8C8D2",
    marginTop: 0,
    fontSize: 16,
  },
  contactAddress: {
    color: "#C8C8D2",
    marginTop: 0,
    fontSize: 16,
    fontStyle: "normal",
  },
  mediaLinks: {
    display: "flex",
    marginTop: 10,
    flexDirection: "column",
  },
  mediaLink: {
    color: "#C8C8D2",
    fontSize: 16,
    marginRight: 10,
    "&:visited": {
      color: "#C8C8D2",
    },
  },
}));

const TJFooter = ({ logo }) => {
  const classes = useStyles();

  return (
    <div className={[classes.footer]}>
      <div className={classes.footerSections}>
        <div className={classes.brandSection}>
          <img src={logo} alt="TJ Footer Logo" className={classes.brandImage} />
          <h2 className={classes.brandTitle}>True Omnichannel Transactions</h2>
          <h2 className={classes.brandSubTitle}>
            Every Channel. One Platform.
          </h2>
        </div>
        <div className={classes.contactSection}>
          <h4 className={classes.contactTitle}>Contact us</h4>
          <address className={classes.contactAddress}>
            No.1 Waterhouse Place,
            <br />
            Block 3, Second Floor,
            <br />
            Century City,
            <br />
            Cape Town 7441
          </address>
          <div className={classes.mediaLinks}>
            <a
              className={classes.mediaLink}
              href="https://transactionjunction.co.za/"
              target="_blank"
              rel="noreferrer"
            >
              transactionjunction.co.za
            </a>

            <a className={classes.mediaLink} href="mailto:info@switch.tj">
              info@switch.tj
            </a>

            <a className={classes.mediaLink} href="tel:+27215253100">
              +27 (0)21 525 3100
            </a>
          </div>
        </div>
        <div className={classes.usefulLinsSection}>
          <h4 className={classes.contactTitle}>Useful links</h4>
          <a
            className={classes.mediaLink}
            href="https://www.bluelabeltelecoms.co.za/privacy-policy.php"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <br />
          <a
            className={classes.mediaLink}
            href="https://www.bluelabeltelecoms.co.za/pdf/about-this-site/blt-paia-manual.pdf"
            target="_blank"
            rel="noreferrer"
          >
            PAIA &amp; POPI Manual
          </a>
        </div>
      </div>
      <div className={classes.footerSocket}>
        Copyright © 2022 Transaction Junction. All Rights Reserved.
      </div>
    </div>
  );
};

export default TJFooter;
