import React from "react";

// React Router
import { useHistory } from "react-router-dom";

// Base Layout Components
import { TJPageHeader, TJCTACard } from "tj-components";
import { Grid } from "@material-ui/core";

// Data import
import apis from "../helpers/apis";

const Home = () => {
  const history = useHistory();

  return (
    <Grid container spacing={0} xs={12}>
      <TJPageHeader title="API Portal" />
      <div className="tj-main">
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          className="container selection-cards"
        >
          {apis.map((api, index) => {
            return (
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                lx={6}
                key={index}
                onClick={() =>
                  history.push({
                    pathname: `/${api.slug}`,
                  })
                }
              >
                <TJCTACard
                  width="100%"
                  title={api.name}
                  minWidth="150px"
                  maxWidth="auto"
                  description={api.excerpt}
                  iconClass="fal fa-cash-register"
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Grid>
  );
};

export default Home;
