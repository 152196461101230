import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

function utiGenerate() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

const refreshUTI = (req) => {
  if (req.body) {
    let jsonBody = JSON.parse(req.body);
    jsonBody["reference"] = utiGenerate();

    return {
      ...req,
      body: JSON.stringify(jsonBody),
    };
  }
  return req;
};

const TJSwagger = ({ url }) => {
  return (
    <SwaggerUI
      url={url}
      tryItOutEnabled
      showMutatedRequest={true}
      requestInterceptor={(req) => refreshUTI(req)}
      docExpansion={"none"}
    />
  );
};

export default TJSwagger;
