export const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    color: "#000133",
    overflow: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  footer: {
    background: "#034EA2",
    fontFamily: "Montserrat-Regular",
    padding: "5rem 10%",
  },
  footerSections: {
    display: "flex",
    paddingBottom: "2rem",
    borderBottom: "1px solid #C8C8D2",
  },
  brandSection: {
    flexBasis: "33%",
    marginRight: "2rem",
  },
  footerSocket: {
    padding: "2rem 0 0",
    color: "#C8C8D2",
    textAlign: "right",
  },
  brandImage: {
    width: 50,
  },
  brandTitle: {
    fontSize: 16,
    color: "#fff",
    textTransform: "uppercase",
    marginBottom: 0,
  },
  brandSubTitle: {
    fontSize: 16,
    color: "#C8C8D2",
    marginTop: 0,
  },
  contactTitle: {
    color: "#C8C8D2",
    marginTop: 0,
    fontSize: 16,
  },
  contactAddress: {
    color: "#C8C8D2",
    marginTop: 0,
    fontSize: 16,
  },
  mediaLinks: {
    display: "flex",
    marginTop: 10,
    alignItems: "center",
  },
  mediaLink: {
    color: "#fff",
    marginRight: 10,
    "&:visited": {
      color: "#fff",
    },
  },
});
