import {
  FETCH_USER_INFO,
  RECEIVED_USER_INFO,
  FETCH_USER_INFO_ERROR,
} from "../actions/actionTypes";

const initialState = {
  data: "",
  isFetching: false,
  isError: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_INFO:
      return Object.assign({}, state, {
        isFetching: true,
        data: "",
        isError: false,
      });
    case RECEIVED_USER_INFO:
      return Object.assign({}, state, {
        data: action.data,
        isFetching: false,
        isError: false,
      });
    case FETCH_USER_INFO_ERROR:
      return Object.assign({}, state, {
        isError: process.env.NODE_ENV !== "production" ? false : true,
        isFetching: false,
        data: process.env.NODE_ENV !== "production" ? "test" : {},
      });
    default:
      return state;
  }
};

export default userReducer;
