import React, { useState } from 'react';

// Library Imports
import { makeStyles } from '@material-ui/core/styles';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// React Router
import { useHistory } from 'react-router';

// MUI Components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Logo from '../static/images/Logo.svg';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';

// Icons Imports
import MoreIcon from '@material-ui/icons/MoreVert';
import apis from '../helpers/apis';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    backgroundColor: '#ffffff',
    justifyContent: 'space-between',
  },
  appBar: {
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: 'relative',
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: '-5px 0px 6px #00000029',
    borderLeft: 'none',
  },
  drawerTitle: {
    marginRight: 20,
    textTransform: 'uppercase',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 1),
    background: '#034EA2',

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-around',
    color: '#fff',
    fontSize: 24,
    fontFamily: 'Montserrat-Regular',
  },
  drawerBody: {
    padding: '20px 40px',
  },
  listItem: {
    borderBottom: '1px solid #707070',

    '&:hover': {
      color: '#00C1FF',
    },

    '&.active': {
      color: '#00C1FF',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  userActionsIcon: {
    color: '#333',
  },
  userActionsIconCaret: {
    fontSize: 14,
    marginLeft: 10,
    color: '#333',
  },
  navLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchPreText: {
    color: '#000',
    marginRight: 10,
  },
  searchDropdown: {
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  inputRoot: {
    color: '#000',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  userIcon: {
    color: '#333',
    '&:hover': {
      background: 'none',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      fontSize: 14,
      display: 'flex',
      flexDirection: 'row',
      color: '#333',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 50,
    },
  },
  desktopItemText: {
    fontSize: 12,
    color: '#64646e',
    marginRight: 5,
  },
  desktopItemLink: {
    fontSize: 14,
    color: '#333',
    marginLeft: -10,
    textUnderline: 'none',
    '&:hover': {
      color: '#00C1FF',
    },
    '&.active': {
      color: '#EE236B',
    },
  },
  pageTitle: {
    flexGrow: 1,
    justifyContent: 'right',
    color: '#C8C8D2',
    fontFamily: 'Montserrat-Regular',
    fontWeight: 800,
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  bottomPush: {
    position: 'fixed',
    bottom: 0,
    display: 'flex',
    width: '100%',
    color: '#fff',
    padding: '10px 40px',
    maxWidth: 360,
    background: '#034EA2',
  },
  bottomPushLink: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const drawerWidth = 360;

const ApiNavigation = ({ pageTitle, onLogout, mobileMenu }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorAPI, setAnchorAPI] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const history = useHistory();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = React.useState(false);
  const [activeLink, setActiveLink] = useState('overview');

  const handleClickAPI = (event) => {
    setAnchorAPI(event.currentTarget);
  };

  const handleCloseAPI = () => {
    setAnchorAPI(null);
  };

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => onLogout()}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* TODO The below may change - will discuss */}
      {/* <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <FontAwesomeIcon icon={faUser} />
        </IconButton>
        <p>Change Password</p>
      </MenuItem> */}
    </Menu>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <img
            src={Logo}
            className={classes.navLogo}
            width="250"
            onClick={() => history.push('/')}
            alt="Transaction Junction Logo"
          />
          <div className={classes.sectionDesktop}>
            <MenuItem
              className={[
                classes.desktopItemLink,
                activeLink === 'overview' ? `active` : '',
              ]}
              onClick={() => setActiveLink('overview')}
            >
              <AnchorLink href="#overview">Overview</AnchorLink>
            </MenuItem>

            <MenuItem
              className={[
                classes.desktopItemLink,
                activeLink === 'implementation-guide' ? `active` : '',
              ]}
              onClick={() => setActiveLink('implementation-guide')}
            >
              <AnchorLink href="#implementation-guide">
                Implementation Guide
              </AnchorLink>
            </MenuItem>

            <MenuItem
              className={[
                classes.desktopItemLink,
                activeLink === 'operations' ? `active` : '',
              ]}
              onClick={() => setActiveLink('operations')}
            >
              <AnchorLink href="#operations">Operations</AnchorLink>
            </MenuItem>

            <Button
              aria-controls="api-menu"
              aria-haspopup="true"
              onClick={handleClickAPI}
              style={{ textTransform: 'none' }}
            >
              APIs
            </Button>
            <Menu
              id="api-menu"
              anchorEl={anchorAPI}
              keepMounted
              open={Boolean(anchorAPI)}
              onClose={handleCloseAPI}
              className={classes.desktopItemLink}
              style={{ marginTop: 40 }}
            >
              <MenuItem onClick={handleCloseAPI}>
                <AnchorLink href="#page-api">Junction@POS REST API</AnchorLink>
              </MenuItem>
            </Menu>

            <MenuItem
              className={[
                classes.desktopItemLink,
                activeLink === 'receipt-formatting' ? `active` : '',
              ]}
              onClick={() => setActiveLink('receipt-formatting')}
            >
              <AnchorLink href="#receipt-formatting">
                Receipt Formatting
              </AnchorLink>
            </MenuItem>

            <MenuItem
              className={[
                classes.desktopItemLink,
                activeLink === 'response-codes' ? `active` : '',
              ]}
              onClick={() => setActiveLink('response-codes')}
            >
              <AnchorLink href="#response-codes">Response Codes</AnchorLink>
            </MenuItem>

            <MenuItem
              className={[
                classes.desktopItemLink,
                activeLink === 'additional-resources' ? `active` : '',
              ]}
              onClick={() => setActiveLink('additional-resources')}
            >
              <AnchorLink href="#additional-resources">
                Additional Resources
              </AnchorLink>
            </MenuItem>

            <MenuItem
              className={[classes.desktopItemLink, classes.pageTitle]}
              onClick={() => setActiveLink('page-api')}
            >
              <AnchorLink href="#page-api">{pageTitle}</AnchorLink>
            </MenuItem>
          </div>
          <IconButton
            color="#000"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {mobileMenu ? (
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="#333"
              >
                <MoreIcon />
              </IconButton>
            </div>
          ) : (
            ''
          )}
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}

      {/* <TJHamburgerMenu /> */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <span className={classes.drawerTitle}>API Portal</span>
          <IconButton onClick={handleDrawerClose}>
            <CloseOutlinedIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.drawerBody}>
          <List>
            {apis.map((api, index) => (
              <ListItem
                onClick={() => history.push(api.slug)}
                className={classes.listItem}
                button
                key={api.name}
              >
                <ListItemText primary={api.name} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default ApiNavigation;
